<template>
  <v-container>
    <!--Header-->
    <v-row>
      <v-col>
        <h3>Select "{{ transaction.transaction_type }}" Type</h3>
      </v-col>
    </v-row>
    <!--Content-->
    <v-row>
      <v-col>
        <v-radio-group v-model="transactionAllocationType">
          <v-radio
            v-for="item in filteredTransactionItems"
            :key="item.name"
            :label="item.label"
            :value="item.name"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <!--Buttons-->
    <AllocationButtonGroup
      @passModalClose="$emit('passModalClose', false)"
      :selectedComponent="selectedComponent"
    />
  </v-container>
</template>
<script>
export default {
  name: "AllocationRadioGroup",
  props: ["transaction", "step_count", "selectedComponent"],
  components: {
    AllocationButtonGroup: () => import("./AllocationButtonGroup.vue"),
  },
  data() {
    return {
      transactionAllocationType: null,
      payment_allocation_items: [
        {
          name: "GeneralJournalEntries",
          label: "General Journal Entry",
          condition: true,
        },
        {
          name: "InterAccountTransfers",
          label: "Inter Account Transfers",
          condition: true,
        },
        {
          name: "CustomerPayments",
          label: "Customer Payments",
          condition: (() => {
            if (this.transaction.transaction_type === "Cash In") {
              return true;
            }
          })(),
        },
        {
          name: "CreditNoteRefunds",
          label: "Credit Note Refunds",
          condition: (() => {
            if (this.transaction.transaction_type === "Cash Out") {
              return true;
            }
          })(),
        },
      ],
    };
  },
  watch: {
    transactionAllocationType(value) {
      if (this.transactionAllocationType !== value) return;
      this.$emit("passComponent", value);
    },
  },
  computed: {
    filteredTransactionItems() {
      return this.payment_allocation_items.filter((item) => {
        return item.condition === true;
      });
    },
  },
};
</script>